import React from "react";
import { PageLayout } from "~components/templates/page-layout";
import { withContext } from "~context";
import * as styles from "./index.module.scss";

const Component: React.FC = () => {
  return (
    <PageLayout
      paddingPattern="larger-than-header"
      meta={{ title: "Cookieポリシー" }}
    >
      <div className={styles.Cookie}>
        <h1>Cookieポリシー</h1>
        <p>
          <ol>
            <li>
              当社が運営するオウンドメディア「Flatt Security
              Blog」「#FlattSecurityMagazine」では、クッキー（Cookie）、SDK（以下「クッキー等」といいます。）を使用しています。当社はクッキー等を通じて、本サービスへの利用者のアクセス情報、閲覧情報等を取得することができます。なお、クッキー等を通じてこれらの情報（以下「クッキー情報」といいます）には、単独で利用者自身を識別し特定できる情報は含まれていません。
            </li>
            <li>
              利用者は本サービス上でのクッキー等の使用を許可しない場合には、利用者のブラウザの設定においてクッキー等を無効にしてください。なお、クッキー等を無効にした場合、本サービスの利便性が損なわれたり、本サービスで提供するサービスのご利用範囲が限定されたりすることがあります。
            </li>
            <li>
              本サービスで利用しているクッキー等の内容とデータ取り扱いの詳細については、以下をご参照ください。
            </li>
          </ol>
        </p>
        <table>
          <tr>
            <th>クッキー等の名称</th>
            <th>送信先</th>
            <th>取得する情報</th>
            <th>利用目的</th>
          </tr>
          <tr>
            <td>
              <a href="https://policies.google.com/technologies/partner-sites?hl=ja">
                Google Analytics
              </a>
            </td>
            <td>Google LLC</td>
            <td>利用者が閲覧したページのURL、滞在時間等</td>
            <td>
              本サービスの利用状況を把握することにより、サービス向上及び利用者の興味やニーズにより適したサービスを提供するため
            </td>
          </tr>
          <tr>
            <td>
              <a href="https://policies.google.com/privacy?hl=ja">Google広告</a>
            </td>
            <td>Google LLC</td>
            <td rowSpan="3">
              利用者が本サービスにアクセスする際に利用しているアプリ、ブラウザ、デバイス、利用者のアクティビティ、現在地情報など
            </td>
            <td rowSpan="3">
              利用者が過去の本サービスに訪問したことを記録し、当社の公告を当社以外のWebサイトやアプリ上で利用者に対して表示するため
            </td>
          </tr>
          <tr>
            <td>
              <a href="https://business.twitter.com/ja/help/ads-policies.html">
                Twitter 広告
              </a>
            </td>
            <td>X Corp.</td>
          </tr>
          <tr>
            <td>
              <a href="https://www.facebook.com/privacy/policies/cookies/?entry_point=cookie_policy_redirect&entry=0">
                Facebook 広告
              </a>
            </td>
            <td>MetaPlatforms, Inc.</td>
          </tr>
        </table>
      </div>
    </PageLayout>
  );
};

export default withContext(Component);
